import React, { useState, useEffect } from 'react';
import '../../styles/Dashboard.css'
import DashboardHome from '../DashboardHome';
import DashboardBankTransfers from '../DashboardBankTransfers';
import DashboardCryptoTransfers from '../DashboardCryptoTransfers';
import DashboardTransactions from '../DashboardTransactions';
import DashboardLoans from '../DashboardLoans';
import DashboardProfileSetting from '../DashboardProfileSetting';
import { baseurl, setCookie, getCookie } from "../../utils/constants";
import DashboardPayPalTransfers from '../DashboardPayPalTransfers';
import DashboardSkrillTransfers from '../DashboardSkrillTransfers';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';

import { setUserdata, clearUserdata } from '../../redux/userdataSlice';

function Dashboard() {
  const dispatch = useDispatch();
  const userdata = useSelector((state) => state.userdata);
  const isAuthenticated = userdata ? Object.keys(userdata).length > 0 : false;

  const loggeduserdata = useSelector((state) => state.userdata.data);
  const navmove = useNavigate();
  const [selectedTab, setSelectedTab] = useState('Dashboard');
  const [showMenuText, setShowMenuText] = useState(true);
  const [showMobileMenu, setShowMobileMenu]=useState(false)
  const [tabLabel, setTabLabel]=useState("");
  const [userData, setUserData]= useState({});
  const [loadingUserData, setLoadingUserData] = useState(false)
  

  // Function to handle tab click
  const handleTabClick = (tabName) => {
    setSelectedTab(tabName);
    setTabLabel(tabName);
    if (deviceWidth<768){
        setShowMobileMenu(!showMobileMenu)
        setShowMenuText(true)
     
    }
  };
  function deleteCookie(cookieName) {
    document.cookie = cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth); 
  useEffect(() => {
      const handleResize = () => {
        setDeviceWidth(window.innerWidth);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    // useEffect(() => {
    //   setLoadingUserData(true)
    //   // Function to check if the cookie exists
    //   const checkCookie = () => {
    //     if (document.cookie.indexOf('usersession') !== -1) {
    //       let uData = getCookie('userData');

    //       setUserData(JSON.parse(uData));
    //       setLoadingUserData(false)
         
    //     } else {
    //       navmove("/login")
    //     }
    //   };
  
    //   // Initial check when component mounts
    //   checkCookie();
  
     
      
    // }, []);

  // Function to render tab content based on selected tab

  useEffect(()=>{
    
           if ((document.cookie.indexOf('usersession') !== -1)&& isAuthenticated ) {
            setUserData(loggeduserdata)
           }
   
   else{
    navmove('/login')
   }
  },[])


  const renderTabContent = () => {
    switch (selectedTab) {
      case 'Dashboard':
       
        return <DashboardHome userData={userData} selectTab={handleTabClick} showMenu={setShowMobileMenu} />;
      case 'Bank Withdrawals':
      
        return <DashboardBankTransfers />;
      case 'Crypto Withdrawals':
      return <DashboardCryptoTransfers/>;
      case 'Paypal Withdrawals':
      return <DashboardPayPalTransfers/>;
      case 'Skrill Withdrawals':
      return <DashboardSkrillTransfers/>;

      case 'Transactions':
       
        return <DashboardTransactions/> ;
      case 'Loans':
       
        return <DashboardLoans/>;
      case 'Profile Settings':
      
        return <DashboardProfileSetting userData={userData}/>;
      default:
        return null;
    }
  };

  return (
    <div className='Dashboard'>
      <div className={deviceWidth>768?"DashboardMenu": showMobileMenu ? "MobileMenu":"DashboardMenu"}  >
        <p>Menu</p>
        <ul>
          <li className={selectedTab === 'Dashboard' ? 'selected' : ''} onClick={() => handleTabClick('Dashboard')}>
            <i className="fa-solid fa-house"></i>
            <p>{showMenuText ? "Dashboard" : ''}</p>
          </li>
          <li className={selectedTab === 'Bank Withdrawals' ? 'selected' : ''} onClick={() => handleTabClick('Bank Withdrawals')}>
            <i className="fa-solid fa-arrow-right-arrow-left"></i>
            <p> {showMenuText ? "Bank Withdrawals" : ''}</p>
          </li>
          <li className={selectedTab === 'Crypto Withdrawals' ? 'selected' : ''} onClick={() => handleTabClick('Crypto Withdrawals')}>
            <i className="fa-solid fa-coins"></i>
            <p>{showMenuText ? "Crypto Withdrawals" : ''}</p>
          </li>
          
          <li className={selectedTab === 'Paypal Withdrawals' ? 'selected' : ''} onClick={() => handleTabClick('Paypal Withdrawals')}>
          <i class="fa-brands fa-cc-paypal"></i>
            <p>{showMenuText ? "Paypal Withdrawals" : ''}</p>
          </li>
          
          <li className={selectedTab === 'Skrill Withdrawals' ? 'selected' : ''} onClick={() => handleTabClick('Skrill Withdrawals')}>
          <i class="fa-brands fa-stripe-s"></i>
            <p>{showMenuText ? "Skrill Withdrawals" : ''}</p>
          </li>
          
          
          
          
          
          
          
          
          
          
          
          <li className={selectedTab === 'Transactions' ? 'selected' : ''} onClick={() => handleTabClick('Transactions')}>
            <i className="fa-regular fa-rectangle-list"></i>
            <p>  {showMenuText ? "Transactions" : ''}</p>
          </li>
          <li className={selectedTab === 'Loans' ? 'selected' : ''} onClick={() => handleTabClick('Loans')}>
            <i className="fa-solid fa-hand-holding-dollar"></i>
            <p> {showMenuText ? "Loans" : ''}</p>
          </li>
          <li className={selectedTab === 'Profile Settings' ? 'selected' : ''} onClick={() => handleTabClick('Profile Settings')}>
            <i className="fa-solid fa-user"></i>
            <p> {showMenuText ? "Profile Settings" : ''}</p>
          </li>
        </ul>

        <div className='logout-button' onClick={()=>{
          deleteCookie("usersession");
          deleteCookie("userData");
          dispatch(clearUserdata());
          window.location.assign("/")
        }}>
        <p >Log out</p>
        <i class="fa-solid fa-right-from-bracket"></i>
        </div>
        
      </div>
      <div className='DashboardMenu-Tab'>
        <div className='menu-toggle' onClick={() => { deviceWidth>768?setShowMenuText(!showMenuText): setShowMobileMenu(!showMobileMenu)&& setShowMenuText(true) }}>
          {/* <i className="fa-solid fa-bars"></i>   */}
         { deviceWidth>768?(<i className="fa-solid fa-bars"></i> ): showMobileMenu?(<i class="fa-solid fa-circle-chevron-left"></i>):(<i className="fa-solid fa-bars"></i> )}
            <p>{tabLabel}</p>
        
        </div>
     
        {
          loadingUserData ? (<i class="fa-solid fa-spinner fa-spin dashboardTabLoading"></i>): (<>{renderTabContent()}</>)
        }

        
      </div>
    </div>
  );
}

export default Dashboard;
