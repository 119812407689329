import React, { useEffect } from 'react';
import './App.css';
import Navbar from './components/Navbar';
import Home from './components/routes/Home';
import RegistrationPage from './components/routes/Registration';
import Login from './components/routes/Login';
import Dashboard from './components/routes/Dashboard';
import Footer from './components/Footer';
import Contact from './components/routes/Contact';
import FrequentlyAskedQuestions from './components/routes/FrequentlyAskedQuestions';
import { Route, Routes, Navigate } from 'react-router-dom';
import AdminMain from './components/AdminMain';
import Privacypolicy from './components/Privacypolicy';
import TOS from './components/TOS';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS CSS
import { useSelector } from 'react-redux';

function App() {
  useEffect(() => {
    AOS.init(); // Initialize AOS library
  }, []);

  const userdata = useSelector((state) => state.userdata);

const isAuthenticated = userdata ? Object.keys(userdata).length > 0 : false;

  


  useEffect(() => {
    console.log("User authentication status changed:", isAuthenticated);
    console.log("Current user data:", userdata);
  }, [ userdata]);

  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/faq" element={<FrequentlyAskedQuestions />} />
        <Route path="/register" element={<RegistrationPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/admin" element={<AdminMain />} />
        <Route path="/privacypolicy" element={<Privacypolicy />} />
        <Route path="/termsofservice" element={<TOS />} />
        <Route 
          path="/dashboard" 
          element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" />} 
        />
        <Route path="*" element={<Home />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
