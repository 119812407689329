import React, { useEffect, useState } from "react";
import "../styles/AdminDashboard.css";
import axios from "axios";
import { baseurl, setCookie, getCookie } from "../utils/constants";
function AdminDashboard() {
  const [searchValue, setSearchValue] = useState("");
  const [allusers, setAllusers] = useState([]);
  const [searchedusers, setSearchUsers] = useState([]);
  const [showsearchresults, setshowsearchresults] = useState(false);
  const [addingfunds, setAddingFunds] = useState(false);
  const [userInputs, setUserInputs] = useState([]);
  const [sendingOTP, setSendingOTP] = useState(false);
  const [verifyingAccounnt, setVerifyingAccount] = useState(false);
  const [linkingAccounnt, setLinkingAccount] = useState(false);

  const handleInputChange = (index, event) => {
    const values = [...userInputs];
    values[index] = event.target.value;
    setUserInputs(values);
  };

  useEffect(() => {
    async function getAllUsers() {
      const requestData = {
        requestTask: "getallUserData",
      };

      let result = await axios.post(baseurl, requestData);
      console.log(result.data)
      setAllusers(result.data);
    }

    getAllUsers();
  }, []);

  function handleSearch() {
    let searchresult = allusers.filter((user) =>
      user.email.includes(searchValue)
    );
    setSearchUsers(searchresult);
    setshowsearchresults(true);
  }
  function generateOTP() {
    var chars =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var OTP = "";
    for (var i = 0; i < 8; i++) {
      OTP += chars[Math.floor(Math.random() * chars.length)];
    }
    return OTP;
  }

  async function addFunds(email, amount, username, currency) {
    setAddingFunds(true);
    const requestData = {
      requestTask: "addfunds",
      email: email,
      amount: amount,
      firstName: username,
      currency,
      
    };
    console.log(requestData)

    let result = await axios.post(baseurl, requestData);
    console.log("RESULT OF CREDIT",result.data);
    if (result.data !== "error" && Array.isArray(result.data)) {
      setAllusers(result.data);
      setAddingFunds(false);
      alert("User credited")
    } else {
      console.log(result.data);
      alert("An error occured");
      setAddingFunds(false);
    }
  }

  async function sendOTP(email, username) {
    setSendingOTP(true);
    let OTP = generateOTP();
    const requestData = {
      requestTask: "sendBillingOTP",
      email: email,
      firstName: username,
      OTP: OTP,
    };

    let result = await axios.post(baseurl, requestData);
    console.log(result.data);

    if (result.data !== "error" && Array.isArray(result.data)) {
      setAllusers(result.data);
      alert("OTP SENT")
      setSendingOTP(false);
    } else {
      console.log(result.data);
      alert("An error occured");
      setSendingOTP(false);
    }
  }

  async function verifyAccount(email, username) {
    setVerifyingAccount(true)
    const requestData = {
      requestTask: "verifyAccount",
      email: email,
      firstName: username,
    };
    let result = await axios.post(baseurl, requestData);
    console.log(result.data);
    if (result.data !== "error" && Array.isArray(result.data)) {
      alert("USER VERIFIED!!!")
      setAllusers(result.data);
      setVerifyingAccount(false)
    } else {
      console.log(result.data);
      alert("An error occured");
      setVerifyingAccount(false)
    }
  }
  async function LinkAccount(email, username) {
    setLinkingAccount(true)
    const requestData = {
      requestTask: "linkAccount",
      email: email,
      firstName: username,
    };
    let result = await axios.post(baseurl, requestData);
    console.log(result.data);
    if (result.data !== "error" && Array.isArray(result.data)) {
      alert("ACCOUNT LINKED!!!")
      setAllusers(result.data);
      console.log(result.data)
      setLinkingAccount(false)
    } else {
      console.log(result.data);
      alert("An error occured");
      setLinkingAccount(false)
    }
  }


  function UserContainer({user, index}){
    return(
      <div>
      <h3>{user.email}</h3>

      <div className="user-controls-buttons">
        <button
          className={
            user.OTP == "NULL" ? "" : "disabled-button"
          }
          onClick={() => {
            if (user.OTP == "NULL") {
              sendOTP(user.email, user.firstName);
            }
          }}
        >
          {sendingOTP ? (
            <i class="fa-solid fa-spinner fa-spin"></i>
          ) : (
            "Send OTP"
          )}
        </button>



        <button  className={
            user.AccountVerification == "NOT VERIFIED" ? "" : "disabled-button"
          }   onClick={() => {
            if (user.AccountVerification == "NOT VERIFIED") {
              verifyAccount(user.email, user.firstName);
            }
          }}>
        {verifyingAccounnt ? (
            <i class="fa-solid fa-spinner fa-spin"></i>
          ) : (
            "Verify Account"
          )}
        </button>


        <button className={
            user.AccountLinking == "NOT LINKED" ? "" : "disabled-button"
          } onClick={() => {
            if (user.AccountLinking == "NOT LINKED") {
              LinkAccount(user.email, user.firstName);
            }
          }}>
        {linkingAccounnt ? (
            <i class="fa-solid fa-spinner fa-spin"></i>
          ) : (
            "Link Account"
          )}
        </button>
        <button>IMF/AML</button>
      </div>

      <div className="funds-container">
        <input
          placeholder="Amount"
          type="number"
          value={userInputs[index]}
          onChange={(event) => handleInputChange(index, event)}
        />
        <div>
          <button
            onClick={() => {
              if (userInputs[index] > 0) {
                addFunds(
                  user.email,
                  userInputs[index],
                  user.firstName,
                  user.currency
                );
              } else {
                alert("Oga add amount");
              }
            }}
          >
            {addingfunds ? (
              <i class="fa-solid fa-spinner fa-spin"></i>
            ) : (
              "Credit Account"
            )}
          </button>
          <button>Debit Account</button>
        </div>
        <details>
          <summary>More Details</summary>
          <div className="details-container">
            <p>
              <b>First Name: </b>
              {user.firstName}
            </p>
            <p>
              <b>Last Name: </b>
              {user.lastName}
            </p>
            <p>
              <b>Status: </b>
              {user.AccountStatus}
            </p>
            <p>
              <b>Account Number: </b>
              {user.AccountNumber}
            </p>
            <p>
              <b>Balance: </b>
              {user.Balance}
            </p>
            <p>
              <b>Currency: </b>
              {user.currency}
            </p>
            <p>
              <b>Address: </b>
              {user.useraddress}
            </p>
            <p>
              <b>Country: </b>
              {user.country}
            </p>

            <p>
              <b>OTP: </b>
              {user.OTP == "NULL" ? "Not Sent" : "Sent"}
            </p>
            <p>
              <b>OTP CODE: </b>
              {user.OTP}
            </p>
            <p>
              <b>Verification: </b>
              {user.AccountVerification}
            </p>
            <p>
              <b>Linking: </b>
              {user.AccountLinking}
            </p>
            <p>
              <b>AML/IMF: </b>
              {user.AMLIMF}
            </p>
          </div>
        </details>
      </div>
    </div>
    );
  }

  return (
    <div className="admin-dashboard">
      <div className="admin-dashboard-wrapper">
        <div className="search-div">
          <input
            type="text"
            placeholder="search-email"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
          />
          {showsearchresults ? (
            <i
              class="fa-solid fa-xmark search-button"
              onClick={() => {
                setSearchValue("");
                setshowsearchresults(false);
              }}
            ></i>
          ) : (
            <i
              class="fa-solid fa-magnifying-glass search-button"
              onClick={() => {
                handleSearch();
              }}
            ></i>
          )}
        </div>

        {allusers.length > 0 ? (
          <div>
            {showsearchresults ? (
              searchedusers.map((user, index) => (
                <div className="user-container">
                  <div key={index}>
                  <UserContainer user={user} index={index}/>
                  </div>
                </div>
              ))
            ) : (
              <div className="user-container">
                {allusers.length > 0 ? (
                  allusers.map((user, index) => (
                    <div key={index}>
                      <UserContainer key={index} user={user} index={index}/>

                   </div>
                  ))
                ) : (
                  <div>Loading...</div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </div>
  );
}

export default AdminDashboard;
