import React, { useEffect, useState } from 'react'
import "../styles/DashboardProfileSetting.css"
import { useDispatch, useSelector } from 'react-redux';
import { setUserTransactions } from "../redux/userTransactionSlice";

function DashboardProfileSetting() {
    const userdata = useSelector((state) => state.userdata);

    const [otp, setOTP]=useState('')
    const [newcode, setNewCode]=useState('')
    const [confirmNewCode, setConfirmNewCode]=useState('')

    function handleOTPChange(e){
        setOTP(e.target.value)
    }
    function handleNewCodeChange(e){
        setOTP(e.target.value)
    }
    function handleConfirmNewCodeChange(e){
        setOTP(e.target.value)
    }

    // useEffect(()=>{
    //     console.log(userData)
    // },[])
    
  return (
    <div className='DashboardProfileSetting'>
        <div className='profile-logo'>
            <div>
            <div className='logo-container'>
            <i class="fa-regular fa-user"></i>
            </div>

            <div className='banking-details'>
                <p className='TDes'><b>{userdata.AccountNumber}</b></p>
                <p className='account-type'>Savings</p>
                <div className={userdata.AccountVerification=="NOT VERIFIED"?"notverified-status":"verified-status"}>{userdata.AccountVerification=="NOT VERIFIED"?"NOT VERIFIED":"VERIFIED"}</div>
            </div>
            </div>
        </div>

        <div className='profile-details'>
            <p className='TDes'><b>Personal Information</b></p>
            <div>
                <label className='profile-detail-label'>Full Name:</label>
                <p>{`${userdata.firstName} ${userdata.lastName}`}</p>
            </div>
            <div>
                <label className='profile-detail-label'>Mobile:</label>
                <p>{userdata.phone}</p>
            </div>
            <div>
                <label className='profile-detail-label'>E-mail:</label>
                <p>{userdata.email}</p>
            </div>
            <div>
                <label className='profile-detail-label'>Location:</label>
                <p>{userdata.useraddress}</p>
                

            </div>
            <div>
                <label className='profile-detail-label'>Country:</label>
                <p>{userdata.country}</p>
            </div>
        </div>

        
        <div className='account-limits'>
            <p className='TDes'><b>Account Limits</b></p>
            <div>
                <label className='account-limits-label'>Sending (Per Transaction):</label>
                <p>$1,000,000.00</p>
            </div>
            <div>
                <label className='account-limits-label'>Receiving (Per Transaction):</label>
                <p>$500,000.00</p>
            </div>
            <div>
                <label className='account-limits-label'>Daily Transaction Limit:</label>
                <p>$500,000.00</p>
            </div>
            <div>
                <label className='account-limits-label'>Debit Card Limit:</label>
                <p>$500,000.00</p>
            </div>
            <div>
                <label className='account-limits-label'>Maximum Balance:</label>
                <p>Unlimited</p>
            </div>
        </div>

        {/* <div className='account-limits'>
            <p className='TDes'><b>KYC</b></p>
           <ul>
            <li>- Driver's License</li>
            <li>- International Passport</li>
            <li>- National I.D Card</li>
           </ul>

           <input
           type='file'
           />
        </div>

        <div className='account-limits'>
            <p className='TDes'><b>Get OTP</b></p>
          <button style={{width:'200px'}}>Place Request</button>

          <div className="form-field">
        <label htmlFor="otp">OTP*</label>
        <input type="number" id="OTP" name="OTP" value={otp} onChange={handleOTPChange} />
      </div>
          <div className="form-field">
        <label htmlFor="newcode">New-pin*</label>
        <input type="number" value={newcode} onChange={handleNewCodeChange} />
      </div>
          <div className="form-field">
        <label htmlFor="newcode">Confirm-pin*</label>
        <input type="number" value={confirmNewCode} onChange={handleConfirmNewCodeChange} />
      </div>
      
     

        </div> */}

     
    </div>
  )
}

export default DashboardProfileSetting