import React, { useEffect } from 'react'
import "../styles/DashboardHome.css"
import homebkg from "../images/desk.png"
import banktransfer from "../images/bank-transfer.png"
import cryptocurrency from "../images/cryptocurrency.png"
import paypal from "../images/paypal.png"
import skrill from "../images/skrill.png"

import DashboardHomeCard from './DashboardHomeCard'
import { baseurl, setCookie, getCookie } from "../utils/constants";
import axios from 'axios';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setUserdata, clearUserdata } from '../redux/userdataSlice';
import { setUserTransactions, clearUserTransactions } from '../redux/userTransactionSlice';
function DashboardHome({selectTab, showMenu

}) {

    const loggedUserdata = useSelector((state) => state.userdata);
    const loggedUserTransactions = useSelector((state) => state.userTransactions);

    const [deviceWidth, setDeviceWidth] = useState(window.innerWidth); 
    const [loadingUserData, setLoadingUserData]=useState(false);
    const [userData, setUserData]= useState({});
    const [accountbalance, setAccountBalance]= useState(10);
    const [userTransactions, setUserTransactions]= useState([])
    // useEffect(() => {
    //     setLoadingUserData(true);
    
    //     async function fetchData() {
    //         try {
    //             const user = getCookie("usersession");
    //             const requestData = {
    //                 requestTask: "getLoginData",
    //                 email: user,
    //             };
    //             const result = await axios.post(baseurl, requestData);
    //             if (result && result.data) {
    //                 setUserData(result.data);
    //                 setAccountBalance(result.data.Balance)
    //                 console.log("User Data is", result.data);

                    
    //             }
    //         } catch (error) {
    //             // Handle errors here, e.g., log them or set an error state.
    //             console.error("Error fetching user data:", error);
    //             alert("Poor internet connection!!!")
    //         } finally {
    //             const user = getCookie("usersession");
    //             const requestData = {
    //                 requestTask: "getUserTransactions",
    //                 email: user,
    //             };
    //             const result = await axios.post(baseurl, requestData);
    //             console.log("User transactions is", result.data);
    //                 setUserTransactions(result.data)
    //             setLoadingUserData(false);
    //         }
    //     }
    
    //     fetchData();
    
    // }, []);
    
    useEffect(()=>{
        setUserData(loggedUserdata);
        setAccountBalance(loggedUserdata.Balance)
        setUserTransactions(loggedUserTransactions)
        console.log ("DATA TYPE",Array.isArray(loggedUserTransactions))
        console.log("USER TRANSACTION: ",loggedUserTransactions)
    },[])

    function formatNumber(number) {
        return number.toLocaleString();
       // return number
    }

  return (
    <div>
       {
        loadingUserData?(<i class="fa-solid fa-spinner fa-spin dashboardTabLoading"></i>):
        ( 
        <div className='dashboard-home'>
        <div className='dashboard-intro'data-aos="fade-in" data-aos-duration="2000">
            <div className='greetings-div'>
                <div className='left'>
                    <h1 className='Theader'>Welcome</h1>
                    <p className='TDes'>{userData.firstName}</p>
                </div>
                <div className='right'>
                    <button style={{width:"100px"}} onClick={()=>{
            selectTab("Bank Withdrawals" ); 
            if (deviceWidth<768){
                showMenu(false);
            }
            }}>
                            +
                    </button>

                </div>
            </div>
            <div className='intro-down'>
                <p>Account Number: {userData.AccountNumber}</p>
               
                <p className='account-label'>{userData.currency} {accountbalance.toLocaleString()}.00</p>
                <p className='currency-denomination'>SAVINGS</p>
            </div>
        </div>
        <div className='paymentOptions'data-aos="zoom-in" data-aos-duration="2000">
           <div onClick={()=>{
            selectTab("Bank Withdrawals" ); 
            if (deviceWidth<768){
                showMenu(false);
            }
            }}>
            <img src={banktransfer} alt=""/>
            <p>Bank Transfer</p>
           </div>
           <div onClick={()=>{selectTab("Crypto Withdrawals");
             if (deviceWidth<768){
                showMenu(false);
            }
           }}>
            <img src={cryptocurrency} alt=""/>
            <p>Crypto Withdrawal</p>
           </div>
           <div onClick={()=>{selectTab("Paypal Withdrawals");
             if (deviceWidth<768){
                showMenu(false);
            }
           }}>
            <img src={paypal} alt=""/>
            <p>Paypal Withdrawal</p>
           </div>
           <div onClick={()=>{selectTab("Skrill Withdrawals");
             if (deviceWidth<768){
                showMenu(false);
            }
           }}>
            <img src={skrill} alt=""/>
            <p>Skrill Withdrawal</p>
           </div>
        </div>
        <div className='dashboard-details'>
            <div className='dashboard-card-wrapper'>
                <div data-aos="slide-up" data-aos-duration="2000">
                    <DashboardHomeCard  
                    cardTitle="Withdawals"
                    cardValue={`0/10`}
                    icon={<i class="fa-solid fa-right-left"></i>}
                    />
                </div>
                <div data-aos="slide-up" data-aos-duration="2000">
                    <DashboardHomeCard  
                    cardTitle="Transfers"
                    cardValue={`${userTransactions.length}`}
                    icon={<i class="fa-solid fa-money-bill-transfer"></i>}
                    />
                </div>
                <div data-aos="slide-up" data-aos-duration="2000">
                    <DashboardHomeCard  
                    cardTitle="Investments"
                    cardValue={`0/100`}
                    icon={<i class="fa-solid fa-building-columns"></i>}
                    />
                </div>
                <div data-aos="slide-up" data-aos-duration="2000">
                    <DashboardHomeCard  
                    cardTitle="Loans"
                    cardValue="0"
                    icon={<i class="fa-regular fa-folder-open"></i>}
                    />
                </div>
            </div>

            <div className='recent-transactions' data-aos="slide-up" data-aos-duration="2000">
                <h3>Recent Bank Transactions</h3>
                {userTransactions.length==0? (<p>No transactions</p>):
                (
                    <div style={{padding:"12px"}}>
                       <p
                       style={{color:'green', fontWeight:'bold'}}
                       >CREDIT****{userData.AccountNumber}</p>
                       <p>{`+${userData.currency} ${formatNumber(userTransactions[userTransactions.length-1].Amount)} `} </p>
                       <p>{userTransactions[userTransactions.length-1].Date}</p>
                    </div>
                    
                )}
            </div>
            <div className='recent-transactions' data-aos="slide-up" data-aos-duration="2000">
                <h3>Recent Crypto Transactions</h3>
                <p>No transactions</p>
            </div>
        </div>

    </div>) 
       }
    </div>
  )
}

export default DashboardHome