import React from 'react'
import "../../styles/Contact.css"

function Contact() {
  return (
    <div>
        <h1 className='PageHeader'>Get in Touch</h1>
        <div className="Get-Started-container">
    <div className="get-started-wrapper" >
                <div className="left">
                    <div className="form-holder" data-aos ="slide-up">
                    <h1 className="Theader">Ready to get started?</h1>
                    <form data-aos ="slide-up"> 
                        <input
                        placeholder="Enter Name *"
                        />
                        <input
                        placeholder="Enter Email *"
                        />
                        <input
                        placeholder="Enter Subject"
                        />
                        <textarea rows={5} placeholder="Message Body *"></textarea>

                        <button>
                            Send Message
                        </button>
                    </form>
                    </div>
                   
                </div>
                <div className="right" data-aos ="slide-up">
                    <h1 className="Theader">Looking for an easy and secured place to save your money?</h1>
                    <p className="TDes">Search no more! Click on the button below to get started.</p>
                    <button className="btn-get-solution">
                        Get Your Solution
                    </button>
                   <hr/>
                </div>

    </div>
</div>

<div className='contact-cards-container'>
    <div data-aos ="bounce-down">
    <i className="fa-solid fa-mobile-screen"></i>
   <p className='TDes'> Call Us</p>
    <p className='card-info'>+447520645543</p>
    </div>

    <div data-aos ="bounce-down">
    <i className="fa-solid fa-location-dot"></i>
   <p className='TDes'> Visit us</p>
    <p className='card-info'>FortressCapitalOnline.com</p>
    </div>

    <div data-aos ="bounce-down">
    <i className="fa-regular fa-envelope"></i>
   <p className='TDes'> Email us</p>
    <p className='card-info' style={{textWrap:"wrap", whiteSpace:"wrap"}}>FortressCapitalOnline@gmail.com</p>
    </div>

    <div data-aos ="bounce-down">
    <i className="fa-solid fa-comments"></i>
   <p className='TDes'>Live Chat</p>
    <p className='card-info'>Chat with us 24/7</p>
    </div>
    
</div>
    </div>
  )
}

export default Contact
