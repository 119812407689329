import React from 'react'
import "../styles/DashboardHomeCard.css"
export default function DashboardHomeCard(props) {
  return (
    <div className='DashboardHomeCard'>
        <div className='left'>
        <p>{props.cardTitle}</p>
        <p className='TDes'>{props.cardValue}</p>
        </div>
 
        <div className='right'>
            <div className='icon-container'>
                {props.icon}
            </div>
        </div>

    </div>
  )
}
