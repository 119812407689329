// store.js
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import userdataReducer from './userdataSlice';
import userTransactionReducer from './userTransactionSlice';

// Configuration object for redux-persist
const persistConfig = {
  key: 'root', // Key for localStorage
  storage,
  whitelist: ['userdata', 'userTransactions'], // Reducers you want to persist
};

// Combine reducers
const rootReducer = combineReducers({
  userdata: userdataReducer,
  userTransactions: userTransactionReducer,
});

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the store with persisted reducer
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Create a persistor
const persistor = persistStore(store);

export { store, persistor };
